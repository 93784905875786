import React from "react";
import { useEffect } from "react";
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import {
     Flex, Loader, View, Heading,
     Alert, Button, Text
     } from '@aws-amplify/ui-react';

function FaceLiveness({faceLivenessAnalysis}) {
    const [loading, setLoading] = React.useState(true);
    const [sessionError, setSessionError] = React.useState("");
    const [sessionId, setSessionId] = React.useState(null);
    const [error, setError] = React.useState(undefined);
   
    const getWindSearch = () => {
        const params = {};
        const url = new URL(window.location.href);
        url.searchParams.forEach((value, key) => {
            params[key] = value;
        });
        return params
    }

    const pushMessageToWindow = (data = '') => {
        window.parent.postMessage(data, '*');
    }

    const winSearch  = getWindSearch();

    const dictionary = {
        en: {
          startScreenBeginCheckText: 'Start Liveness Check',
        },
    };

    const endpoint = process.env.REACT_APP_ENV_API_URL ? process.env.REACT_APP_ENV_API_URL : ''

    const decodedEndpoint = decodeURIComponent(escape(atob(winSearch.endpoint || '')));
    // const decodedEndpoint = endpoint;

    useEffect(() => {
        /*
         * API call to create the Face Liveness Session
         */
        const fetchCreateLiveness = async () => {
            try {
                const response = await fetch(decodedEndpoint + '/liveness/start',  {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ session_id: winSearch.session_id })
                });
                const data = await response.json();
    
                if(data.success){
                    setSessionId(data.data.session_id)
                    setLoading(false);
                }else{
                    pushMessageToWindow({message: data.message })
                    setSessionError(data.message)
                }
            } catch (error) {
                pushMessageToWindow({message: 'Unable to start liveness test session' }, '*');
                setSessionError('The page you are looking for might have been removed had its name changed or is temporarily unavailable.')
            }
            
        };
        fetchCreateLiveness();

    },[])

    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async () => {
        /*
         * API call to get the Face Liveness Session result
         */
        try {
            const response = await fetch(decodedEndpoint + '/liveness/update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ session_id: sessionId })
                }

            );
            const data = await response.json();
            console.log(data)
            if(data.success){
                if(data.data.is_live){
                    pushMessageToWindow(data.data);
                }else{
                    pushMessageToWindow({message: "Liveness test failed" });
                }
                faceLivenessAnalysis(data.data)
            }else{
                pushMessageToWindow({message: data.message });
                setLoading(true);
                setSessionError(data.message)
            }

        } catch (error) {
            pushMessageToWindow({message: 'Unable to start liveness test session' });
            setSessionError('The page you are looking for might have been removed had its name changed or is temporarily unavailable.')
        }
    };

    const CustomError = React.useCallback(() => {
        return (
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Flex
              backgroundColor="white"
              direction="column"
              justifyContent="center"
              padding="15px"
            >
              <Heading color="black">Something went wrong</Heading>
              <Text>{error?.error.message}</Text>
              <Button variation="primary" onClick={() => pushMessageToWindow({message: error?.error.message || 'Something went wrong' })} type="button">Try again?</Button>
            </Flex>
          </Flex>
        );
    }, [error]);

    return (
        <>
            {loading ? (
                <Flex alignItems="center">
                    <View as="div" width="100%">
                        <Loader 
                        variation="linear"/>
                        { sessionError ?  <Alert
                            as="div"
                            marginTop="10px"
                            variation="error"
                            isDismissible={false}
                            hasIcon={true}
                            heading="Something went wrong"
                        >
                        { sessionError }. Please go back to the form.
                        </Alert> : null }
                    </View>
               </Flex>
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="us-east-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    disableInstructionScreen={true}
                    onError={(error) => {
                        setError(error)
                        console.error(error);
                    }}
                    displayText={ dictionary['en'] }
                    components={{
                        PhotosensitiveWarning: () => {
                            return (
                                <Alert
                                    variation="info"
                                    isDismissible={false}
                                    hasIcon={true}
                                    heading="Caution"
                                >
                                    This check uses flashing lights. Proceed with caution if you are sensitive to light.
                                </Alert>
                            );
                        },
                        ErrorView: CustomError,
                    }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
